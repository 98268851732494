.show-hide-input {
    display: flex;
    position: relative;
    align-items: center;
}

.show-hide-input>button {
    position: absolute;
    right: 2px;
    border: 0;
    background-color: transparent;
    color: var(--dark) !important;
}