$primary: #b8f2e6;
$secondary: #ffa69e;
$light: #faf3dd;
$dark: #5e6472;
$info: #aed9e0;

$font-weight-light: 500;
$font-weight-normal: 500;
$font-weight-bold: 600;
$headings-font-weight: 700;

@import './node_modules/bootstrap/scss/bootstrap';

