@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


* {
  margin: 0;
  padding: 0;

}

html {
  font-weight: 500 !important;
  font-size: 16px;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'Raleway', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  --light: #fffaea;
  --dark: #5e6472;
  --primary: #b8f2e6;
  --secondary: #ffa69e;
  --info: #aed9e0;

}

#root {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  background-color: var(--dark);
  color: var(--light) !important;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}