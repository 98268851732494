.App {
  text-align: center;
  max-width: 768px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.RoutesList {
  padding: 1em;
  flex: 1;
}

.modal, .form-group, .form-control, .form-label {
  color: var(--dark) !important;
}

/* .modal-header, .modal-body, .modal-footer {
  background-color: var(--light);
} */

.modal-open {
  padding-right: 0px !important;
}

.form-check-input {
  transform: scale(1.2) !important;
}

.css-8g9rcz-control:focus-within, .css-t3ipsp-control:focus-within {
  box-shadow: 0 0 0 0.2rem rgba(184, 242, 230, 0.25) !important;
}

.btn {
  font-size: 14px !important;
  color: var(--light) !important;
}

.accordion {
  color: var(--info) !important;
}

.accordion-item {
  background-color: var(--light) !important;
  font-weight: 500;
}

.accordion-button.collapsed{
  background-color: var(--info) !important;
  color: var(--dark)!important;
  font-weight: 500;
}

.accordion-button{
  background-color: var(--dark) !important;
  font-weight: 600;
}

.brand {
  font-weight: 600;
  font-size: 24px !important;
}

.react-datepicker, .react-datepicker__day {
  color: var(--dark) !important;
  font-family:'Raleway', sans-serif !important;
}

.react-datepicker__header, .react-datepicker__current-month, .react-datepicker__day-name {
  background-color: var(--light) !important;
  color: var(--dark) !important;
}

.react-datepicker__day--selected {
  color: var(--light) !important;
  background-color: var(--info) !important;


}
.react-datepicker__day--keyboard-selected {
  background-color: white !important;
}

.react-datepicker__day--selected:hover {
  background-color: var(--secondary) !important;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: var(--primary) !important;
  font-size: 18px !important;
  content: '✕' !important;

}

footer {
  margin: 2em auto;
  padding: 0;
  width: 100%;
}

footer>p {
  font-size: 14px;
  margin: 0;
}

footer>a {
  font-size: 12px;
}

td, th {
  vertical-align: middle !important;
}

small {
  font-size: 12px !important;
}


.icon-button {
  height: 14px;
}

.icon-button:hover {
  cursor: pointer;
}

