.input-icon {
    position: relative;
}

.input-icon>i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 2em;
    text-align: center;
    font-style: normal;
}

.input-icon>input {
    padding-left: 1.5em;
    padding-right: 0;
}

.input-icon>span {
    padding-left: 1.5em;
    padding-right: 0;
}

.input-icon-right>i {
    right: 0;
}

.input-icon-right>input {
    padding-left: 0;
    padding-right: 1.5em;
    text-align: right;
}

.input-icon-right>span {
    padding-left: 0;
    padding-right: 1.5em;
    text-align: right;
    display: block;
}

@media (max-width: 400px) {
    .input-icon>i {
        width: 1.5em;
    }

    .input-icon>input {
        padding-left: 1.2em;
        padding-right: 0;
    }

    .input-icon>span {
        padding-left: 1.2em;
        padding-right: 0;
    }

    .input-icon-right>i {
        right: 0;
    }

    .input-icon-right>input {
        padding-left: 0;
        padding-right: 1.3em;
        text-align: right;
    }

    .input-icon-right>span {
        padding-left: 0;
        padding-right: 1.3em;
        text-align: right;
        display: block;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.expense-card {
    position: relative;
}

.small-delete {
    background-color: transparent !important;
    border: 0 !important;
    color: var(--secondary) !important;
    padding: 0 !important;
}

.small-delete-corner {
    position: absolute;
    right: 0;
    bottom: 2px;
}

.info-icon {
    color: var(--info) !important;
}